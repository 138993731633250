#NavBar {
  transition: 1s;
  box-shadow: 0px 1px 20px -5px #4fc0d045;
  position: fixed !important;
  z-index: 9999;
  width: 100%;
  background: var(--wColor);
  backdrop-filter: blur(50px);
  nav {
    min-height: 3.5rem;
    box-shadow: 0 -10px 20px var(--Color1) !important;
    .navbar-brand,
    .nav-link {
      color: var(--bColor) !important;
      font-weight: 600;
    }
    .main-btn {
      color: #fff !important;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }
    .navbar-toggler {
      position: relative;
      border: none;
      box-shadow: none;
      outline: none;
      &:focus {
        box-shadow: none;
      }
      &[aria-expanded="true"] span:nth-child(2) {
        opacity: 0;
      }
      &[aria-expanded="true"] span:first-child,
      &[aria-expanded="true"] span:last-child {
        top: 6px;
        background-color: var(--Color1); //bColor
      }
      &[aria-expanded="true"] span:first-child {
        transform: rotate(45deg);
      }
      &[aria-expanded="true"] span:last-child {
        transform: rotate(-45deg);
      }
      span {
        background-color: var(--bColor);
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        transition: all 0.3s ease;
        &:first-child {
          top: 0;
        }
        &:nth-child(2) {
          top: 6px;
        }
        &:last-child {
          top: 12px;
        }
      }
    }
    .navbar-nav {
      .nav-item {
        display: flex;
        align-items: stretch;
        justify-content: center;
        .nav-link {
          opacity: 0.7;
          transition: 0.5s;
          &:hover {
            opacity: 1;
          }
          &.active {
            opacity: 1;
          }
        }
      }
    }
    button:not(.navbar-toggler) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 10px;
      background: transparent;
      padding: 0.3rem 1.5rem;
      &:hover {
        background: var(--Color1);
        box-shadow: 0 0 2rem 0.5rem rgb(27 107 147 / 10%);
      }
    }
    button.navbar-toggler {
      margin: 0 0.5rem;
    }
  }
}

.NavBarFixed {
  background: rgb(255 255 255 / 75%) !important;
}
