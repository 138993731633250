.MainBtn {
  position: relative;
  padding: 0.5rem 1rem;
  border: 1px solid var(--Color1);
  background: var(--wColor);
  color: var(--Color1);
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  overflow: hidden;
  transition: 0.2s ease-in;
  &:hover {
    color: var(--wColor);
    background: var(--Color1);
    box-shadow: 0 0 2rem 0.5rem #1b6b9380;
    transition: 0.2s ease-out;
  }
  &:hover::before {
    animation: animationMainBtn 0.5s linear;
  }
  &::before {
    content: "";
    display: block;
    width: 0px;
    height: 100%;
    position: absolute;
    top: 5%;
    left: 0%;
    opacity: 0;
    background: #fff;
    box-shadow: 0 0 50px 30px #fff;
    transform: skewX(-20deg);
  }
  &:active {
    transition: box-shadow 0.2s ease-in;
  }
}

@keyframes animationMainBtn {
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    left: 100%;
  }
}
