@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
:root {
  --Color1: #1b6b93;
  --Color2: #d0eff3;
  --Color3: #a2ff86;
  --Color4: #ff8686;
  --Color5: #2a90c4;
  --Color6: #092c3e;
  --wColor: #fff;
  --bColor: #000;
  --gColor: #6d6d6d;
  --gColor1: #888888;
  --gColor2: #eaeaea;
  --gColor3: #f8f8f8;
}

* {
  outline: none !important;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

body {
  overflow-x: hidden !important;
  font-family: "Roboto", sans-serif;
}

::-moz-selection {
  background-color: var(--Color1);
  color: var(--wColor);
}

::selection {
  background-color: var(--Color1);
  color: var(--wColor);
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--Color1);
}

section:not(#MainSecHome) {
  padding-block: 2rem;
}

.main-color {
  color: var(--Color1);
}

.text-justify {
  text-align: justify;
}

.main-banner {
  width: 100%;
  text-align: center;
  margin: 25px 0;
}
.main-banner .main-banner-h2 {
  position: relative;
  text-transform: uppercase;
  font-size: calc(1.5rem + 5vw);
  font-weight: 700;
  color: var(--gColor2);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.main-banner .main-banner-p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: calc(1.5rem + 1vw);
  font-weight: 600;
  width: 100%;
}
.main-banner .main-banner-p::after {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 100px;
  height: 3px;
  background-color: var(--Color1);
  left: 50%;
  transform: translate(-50%, -50%);
}

.toUp {
  position: fixed;
  bottom: 25px;
  right: 25px;
  font-size: 1.3rem;
  color: var(--Color1);
  cursor: pointer;
  animation: toUp 1s infinite alternate;
  z-index: 9998;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
@media (max-width: 991px) {
  .toUp {
    font-size: calc(0.8rem + 1vw);
  }
}
@keyframes toUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}

#Loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(250, 250, 250, 0.9) !important;
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  flex-direction: column;
}
#Loader .center {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#Loader .center .wave {
  width: 10px;
  height: 75px;
  background: var(--Color1);
  margin: 10px;
  animation: wave 1s infinite ease-in-out;
  border-radius: 20px;
}
#Loader .center .wave:nth-child(1) {
  animation-delay: 0s;
  transform: scale(0);
}
#Loader .center .wave:nth-child(2) {
  animation-delay: 0.1s;
  transform: scale(0.1);
}
#Loader .center .wave:nth-child(3) {
  animation-delay: 0.2s;
  transform: scale(0.2);
}
#Loader .center .wave:nth-child(4) {
  animation-delay: 0.3s;
  transform: scale(0.3);
}
#Loader .center .wave:nth-child(5) {
  animation-delay: 0.4s;
  transform: scale(0.4);
}
#Loader .center .wave:nth-child(6) {
  animation-delay: 0.5s;
  transform: scale(0.5);
}
#Loader .center .wave:nth-child(7) {
  animation-delay: 0.6s;
  transform: scale(0.6);
}
#Loader .center .wave:nth-child(8) {
  animation-delay: 0.7s;
  transform: scale(0.7);
}
#Loader .center .wave:nth-child(9) {
  animation-delay: 0.8s;
  transform: scale(0.8);
}
#Loader .center .wave:nth-child(10) {
  animation-delay: 0.9s;
  transform: scale(0.9);
}
@keyframes wave {
  0% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
  }
}

#Footer {
  background-color: var(--Color6);
}
#Footer i,
#Footer svg:not(.xIcon) {
  padding-inline-start: 0 !important;
}
#Footer .xIcon {
  padding-inline-start: 0.5rem !important;
}
#Footer .Links-Div {
  display: flex;
  flex-direction: column;
}
#Footer .link {
  color: #8c9499;
  text-decoration: none;
  transition: 0.5s;
}
#Footer .link:hover {
  color: var(--Color2);
  text-decoration: none;
}
#Footer button {
  background-color: transparent;
  color: var(--wColor);
  border: 1px solid var(--wColor);
}
#Footer button:hover {
  box-shadow: 0 0 2rem 0.5rem rgba(255, 255, 255, 0.2);
}
#Footer button .MainBtn {
  background: transparent;
  text-decoration: none;
  color: inherit;
}
#Footer .all-div {
  display: flex;
  flex-direction: column;
  align-items: start;
}
@media (max-width: 991px) {
  #Footer p.my-3 {
    margin-top: 0 !important;
  }
  #Footer .icons {
    justify-content: center;
  }
  #Footer .all-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.alert {
  padding: 0.5rem 0 0 0;
  margin: 0;
  color: var(--Color4);
}

@media (max-width: 991px) {
  .rowX {
    flex-direction: column-reverse;
  }
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.three-doots {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.wrap {
  flex-wrap: wrap;
}
.wrap > p {
  margin-inline-end: 0.5rem;
}

.show-more {
  text-decoration: none !important;
  color: var(--Color1) !important;
  opacity: 0.8;
  transition: 0.5s;
}
.show-more:hover {
  opacity: 1;
}

#NewsSecHome,
#ProjectsSecHome,
#CertificatesSecHome {
  background: var(--gColor3);
}

.ms-auto {
  margin-left: unset !important;
  margin-right: unset !important;
  margin-inline-start: auto !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 10000 !important;
}

.fade.modal.show {
  z-index: 9999;
}

.cursor-pointer {
  cursor: pointer;
}

#MainSecHome {
  position: relative;
  padding: 0;
}
@media (max-width: 767px) {
  #MainSecHome {
    height: 100vh;
  }
  #MainSecHome .carousel .carousel-inner .carousel-item img {
    height: 100vh;
  }
}
#MainSecHome .carousel {
  direction: ltr;
}
#MainSecHome .carousel .carousel-inner .carousel-item {
  position: relative;
}
#MainSecHome .carousel .carousel-inner .carousel-item img {
  width: 100%;
}
@media (max-width: 767px) {
  #MainSecHome .carousel .carousel-inner .carousel-item img {
    width: auto;
  }
}
#MainSecHome .carousel .carousel-inner .carousel-item .text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.75), transparent);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
#MainSecHome .carousel .carousel-inner .carousel-item .text h2 {
  font-size: 4.5rem;
  transition: 1s 0.3s;
}
@media (max-width: 767px) {
  #MainSecHome .carousel .carousel-inner .carousel-item .text h2 {
    font-size: 2.5rem;
  }
}
#MainSecHome .carousel .carousel-inner .carousel-item .text p {
  transition: 1s;
}
#MainSecHome .carousel .carousel-inner .carousel-item .text h2,
#MainSecHome .carousel .carousel-inner .carousel-item .text p {
  color: #fff;
  position: relative;
  left: 0;
  bottom: 0;
  transform: scale(1);
}
#MainSecHome .carousel .carousel-inner .carousel-item:not(.active) .text h2,
#MainSecHome .carousel .carousel-inner .carousel-item:not(.active) .text p {
  left: 100%;
  bottom: 100%;
  transform: scale(0);
}
#MainSecHome .carousel .allBtns button {
  height: 3rem;
  width: 3rem;
  opacity: 1;
  overflow: hidden;
}
#MainSecHome .carousel .allBtns button:hover::after {
  left: 0;
}
#MainSecHome .carousel .allBtns button:hover svg {
  color: var(--Color3);
}
#MainSecHome .carousel .allBtns button::after {
  content: "";
  position: absolute;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: var(--Color3);
  transition: 0.5s;
}
@media (max-width: 576px) {
  #MainSecHome .carousel .allBtns button {
    height: 2.5rem;
    width: 2.5rem;
  }
}
#MainSecHome .carousel .allBtns button svg {
  color: var(--Color1);
  transition: 0.5s;
}
#MainSecHome .carousel .allBtns .carousel-control-prev {
  top: calc(50% - 3rem);
}
#MainSecHome .carousel .allBtns .carousel-control-next {
  top: 50%;
}
@media (max-width: 576px) {
  #MainSecHome .carousel .allBtns .carousel-control-prev {
    top: calc(50% - 2.5rem);
  }
}
#MainSecHome .scroll {
  height: 50px;
  width: 30px;
  border: 2px solid #fff;
  cursor: pointer;
  z-index: 1;
}
#MainSecHome .scroll .scrollX {
  width: 10px;
  height: 10px;
  animation: scroll 1.2s infinite linear;
}
@keyframes scroll {
  0% {
    top: 0;
    opacity: 0.2;
  }
  100% {
    top: calc(100% - 16px);
    opacity: 1;
  }
}

#AboutSecHome .row .left-side button a {
  color: var(--Color1);
  text-decoration: none;
  transition: 0.5s;
}
#AboutSecHome .row .left-side button:hover a {
  color: var(--wColor);
}
@media (min-width: 1400px) {
  #AboutSecHome .row .left-side .w-md-70 {
    width: 70%;
  }
}
#AboutSecHome .row .right-side {
  align-items: center;
}
@media (max-width: 991px) {
  #AboutSecHome .row .right-side img {
    width: 70%;
  }
}
#AboutSecHome .row .card {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  transform: scale(1);
  transition: 0.5s;
}
#AboutSecHome .row .card:hover {
  box-shadow: 0 0 20px -7px var(--Color1);
}
#AboutSecHome .row .card .main-counter {
  display: flex;
  flex-direction: row-reverse;
  font-weight: 600;
}

#NewsSecHome .newsDiv,
#NewsDetails .newsDiv {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
#NewsSecHome .newsDiv .text,
#NewsDetails .newsDiv .text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 0.5rem;
  background: rgba(59, 94, 110, 0.5019607843);
  color: var(--wColor);
  font-size: 1.5rem;
  font-weight: 600;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  transition: 0.5s;
}
#NewsSecHome .newsDiv:hover .text,
#NewsDetails .newsDiv:hover .text {
  left: 0;
  bottom: 0;
}

#ServicesSecHome .atropos.my-atropos,
#Projects .atropos.my-atropos {
  height: 100%;
}
#ServicesSecHome .atropos.my-atropos .card,
#Projects .atropos.my-atropos .card {
  height: 100%;
}
#ServicesSecHome .atropos.my-atropos .card .card-body,
#Projects .atropos.my-atropos .card .card-body {
  display: flex;
  flex-direction: column;
}
#ServicesSecHome .atropos.my-atropos .card .card-body .head-of-body,
#Projects .atropos.my-atropos .card .card-body .head-of-body {
  flex-grow: 1;
  background: linear-gradient(45deg, rgba(27, 107, 147, 0.5215686275), transparent);
}
#ServicesSecHome .atropos.my-atropos .card .card-body img,
#Projects .atropos.my-atropos .card .card-body img {
  aspect-ratio: 2/1;
}
#ServicesSecHome .card-body,
#Projects .card-body {
  padding: 0;
}
#ServicesSecHome .card-body .head-of-body,
#Projects .card-body .head-of-body {
  position: relative;
  overflow: hidden;
}
#ServicesSecHome .card-body .head-of-body::after,
#Projects .card-body .head-of-body::after {
  content: "";
  position: absolute;
  width: 40%;
  height: 200%;
  left: -100%;
  top: -50%;
  background-color: rgba(255, 255, 255, 0.1);
  transition: 1s;
  transform: rotate(30deg);
}
#ServicesSecHome .card-body .head-of-body:hover::after,
#Projects .card-body .head-of-body:hover::after {
  left: 150%;
}
#ServicesSecHome .card-body .body-of-body,
#Projects .card-body .body-of-body {
  padding: 1rem;
}
#ServicesSecHome .card-body .body-of-body .card-title,
#Projects .card-body .body-of-body .card-title {
  color: var(--Color1);
}
#ServicesSecHome .card-body .body-of-body a,
#Projects .card-body .body-of-body a {
  color: var(--bColor);
  text-decoration: none;
  display: inline-block;
  opacity: 0.8;
  transition: 0.5s;
}
#ServicesSecHome .card-body .body-of-body a p,
#Projects .card-body .body-of-body a p {
  margin: 0 !important;
}
#ServicesSecHome .card-body .body-of-body a:hover,
#Projects .card-body .body-of-body a:hover {
  opacity: 1;
}
#ServicesSecHome .card-body .body-of-body .card-text,
#Projects .card-body .body-of-body .card-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
#ServicesSecHome .fade-enter,
#Projects .fade-enter {
  opacity: 0;
  transform: translateY(20px);
}
#ServicesSecHome .fade-enter-active,
#Projects .fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s, transform 1s;
}
#ServicesSecHome .fade-exit-active,
#Projects .fade-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s, transform 1s;
}

#ProjectsSecHome {
  overflow-x: hidden !important;
}
#ProjectsSecHome .vertical-timeline::before {
  background: var(--gColor2);
}
#ProjectsSecHome .vertical-timeline-element-icon.bounce-in {
  background: var(--Color1);
  color: var(--wColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

#PartnersSecHome .swiper-wrapper {
  align-items: center;
}
#PartnersSecHome .swiper-wrapper .partnerDiv .partnerImg {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  max-width: 100%;
  filter: grayscale(0.7);
  transition: 0.5s;
}
#PartnersSecHome .swiper-wrapper .partnerDiv .partnerImg:hover {
  filter: grayscale(0);
}

#CertificatesSecHome .containerX {
  display: flex;
  justify-content: center;
}
#CertificatesSecHome .containerX .certificatesImg {
  transition: 0.5s;
}
#CertificatesSecHome .containerX .certificatesImg:hover {
  transform: scale(1.02);
}

#ContactSecHome .container .form .row .form-outline,
#CareerDetails .container .form .row .form-outline {
  margin: 10px 0;
  position: relative;
}
#ContactSecHome .container .form .row .form-outline .invalidTooltip,
#CareerDetails .container .form .row .form-outline .invalidTooltip {
  position: relative;
  top: 0;
  margin: 10px 0;
  font-size: 18px;
  background-color: transparent;
  color: var(--Color1);
  padding: 0;
}
#ContactSecHome .container .form .row .form-outline input,
#ContactSecHome .container .form .row .form-outline textarea,
#CareerDetails .container .form .row .form-outline input,
#CareerDetails .container .form .row .form-outline textarea {
  box-shadow: none;
  outline: none;
  border: 2px solid var(--Color1);
  color: var(--Color1);
  background: transparent;
}
#ContactSecHome .container .form .row .form-outline input:focus,
#ContactSecHome .container .form .row .form-outline textarea:focus,
#CareerDetails .container .form .row .form-outline input:focus,
#CareerDetails .container .form .row .form-outline textarea:focus {
  border: 2px solid var(--Color5);
}
#ContactSecHome .container .form .row .form-outline .textarea,
#CareerDetails .container .form .row .form-outline .textarea {
  height: 150px;
  vertical-align: bottom;
}
#ContactSecHome .container .form .row .form-outline #submit,
#CareerDetails .container .form .row .form-outline #submit {
  cursor: not-allowed;
}

#About .rowX .left-side ul {
  list-style: none;
}
#About .rowX .left-side ul li {
  position: relative;
}
#About .rowX .left-side ul li::before {
  content: "➤";
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--Color1);
  font-size: 1.2rem;
}
#About .rowX .right-side .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded {
  background: var(--gColor3);
}

#NewsDetails .all-news p,
#NewsSecHome .all-news p {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: var(--gColor);
}
#NewsDetails .swiper-wrapper img,
#NewsSecHome .swiper-wrapper img {
  aspect-ratio: 2/1;
}

#Careers .text-justify {
  line-height: 1.8rem;
}
#Careers .card {
  padding: 1rem;
}
#Careers .card .allCard:not(.allCardX) {
  align-items: center;
}
#Careers .card .allCard {
  display: flex;
}
#Careers .card .allCard i,
#Careers .card .allCard svg {
  background-color: var(--gColor3);
  border-radius: 10px;
  margin-inline-end: 0.5rem;
  font-size: 1.5rem;
  padding: 0.5rem;
  height: -moz-fit-content;
  height: fit-content;
}
#Careers .card button:hover {
  box-shadow: 0 0 20px -2px var(--Color1);
}

#CareerDetails .allCard .allCardX {
  display: flex;
  align-items: center;
}
#CareerDetails .allCard .allCardX h5 {
  margin: 0;
}
#CareerDetails .allCard .allCardX i,
#CareerDetails .allCard .allCardX svg {
  background-color: var(--gColor3);
  border-radius: 10px;
  margin-inline-end: 0.5rem;
  font-size: 1.5rem;
  padding: 0.5rem;
  height: -moz-fit-content;
  height: fit-content;
}
#CareerDetails .allCard p {
  margin-bottom: 0.5rem;
  text-align: justify;
}

#Gallery .main {
  margin-top: 0.5rem;
}
#Gallery .title {
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin-bottom: 1.5rem;
}
#Gallery .title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100px;
  height: 3px;
  background-color: var(--Color1);
}
#Gallery .imgs {
  -moz-columns: 3 250px;
       columns: 3 250px;
}
#Gallery .imgs img {
  margin-bottom: 1rem;
}

#Projects input {
  box-shadow: none;
  outline: none;
  border: 2px solid var(--Color1);
  color: var(--Color1);
  background: transparent;
}
#Projects input:focus {
  border: 2px solid var(--Color5);
}

#NotFound {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
#NotFound a {
  color: var(--Color1);
  text-decoration: none;
}
#NotFound .error {
  width: 50%;
}
@media (max-width: 991px) {
  #NotFound .error {
    width: 100%;
  }
}

.ar {
  font-family: "Cairo", sans-serif;
}
.ar p.MuiTypography-root {
  font-family: "Cairo";
}
.ar .vertical-timeline-element-content .vertical-timeline-element-date {
  float: right !important;
  text-align: end;
  font-family: "Roboto", sans-serif;
}
.ar #NavBar ul {
  padding: 0;
}
.ar #NavBar .langDiv button {
  font-family: "Roboto", sans-serif;
}
.ar #Footer .row div:not(.align-items-centerX, .icons) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ar #Footer .row p {
  text-align: start;
}
.ar #Footer .row ul {
  padding: 0;
}
@media (max-width: 991px) {
  .ar #Footer .row .div {
    align-items: center !important;
  }
  .ar #Footer .row .div .Links-Div,
  .ar #Footer .row .div .all-div {
    align-items: center !important;
  }
  .ar #Footer .row .div p,
  .ar #Footer .row .div a {
    text-align: center !important;
  }
}
.ar .toUp {
  right: unset;
  left: 25px;
}
.ar #ProjectsSecHome .vertical-timeline-element-content.bounce-in {
  direction: rtl;
}
.ar #About .rowX .left-side ul li::before {
  transform: translateY(-50%) scaleX(-1);
  right: -25px;
  left: unset;
}
.ar #Gallery .title::after {
  left: unset;
  right: 0;
}/*# sourceMappingURL=index.css.map */