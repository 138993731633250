@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
// shared styles
:root {
  --Color1: #1b6b93;
  --Color2: #d0eff3;
  --Color3: #a2ff86;
  --Color4: #ff8686;
  --Color5: #2a90c4;
  --Color6: #092c3e; //#18273e

  --wColor: #fff;
  --bColor: #000;
  --gColor: #6d6d6d;
  --gColor1: #888888;
  --gColor2: #eaeaea;
  --gColor3: #f8f8f8;
}
* {
  outline: none !important;
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body {
  overflow-x: hidden !important;
  font-family: "Roboto", sans-serif;
}
::selection {
  background-color: var(--Color1);
  color: var(--wColor);
}
::-webkit-scrollbar {
  width: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background-color: var(--Color1); //var(--Color2)
}
section:not(#MainSecHome) {
  padding-block: 2rem;
}
.main-color {
  color: var(--Color1);
}
.text-justify {
  text-align: justify;
}

// main banner
.main-banner {
  width: 100%;
  text-align: center;
  margin: 25px 0;
  .main-banner-h2 {
    position: relative;
    text-transform: uppercase;
    font-size: calc(1.5rem + 5vw);
    font-weight: 700;
    color: var(--gColor2);
    user-select: none;
  }
  .main-banner-p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(1.5rem + 1vw);
    font-weight: 600;
    width: 100%;
    &::after {
      content: "";
      position: absolute;
      bottom: -5px;
      width: 100px;
      height: 3px;
      background-color: var(--Color1); //var(--Color2)
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

// to Up btn
.toUp {
  position: fixed;
  bottom: 25px;
  right: 25px;
  font-size: 1.3rem;
  color: var(--Color1);
  cursor: pointer;
  animation: toUp 1s infinite alternate;
  z-index: 9998;
  // background: var(--Color2);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  @media (max-width: 991px) {
    font-size: calc(0.8rem + 1vw);
  }
  @keyframes toUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(10px);
    }
  }
}

// Loader
#Loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(250, 250, 250, 0.9) !important;
  backdrop-filter: blur(25px);
  flex-direction: column;
  .center {
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    .wave {
      width: 10px;
      height: 75px;
      background: var(--Color1);
      margin: 10px;
      animation: wave 1s infinite ease-in-out;
      border-radius: 20px;
      &:nth-child(1) {
        animation-delay: 0s;
        transform: scale(0);
      }
      &:nth-child(2) {
        animation-delay: 0.1s;
        transform: scale(0.1);
      }
      &:nth-child(3) {
        animation-delay: 0.2s;
        transform: scale(0.2);
      }
      &:nth-child(4) {
        animation-delay: 0.3s;
        transform: scale(0.3);
      }
      &:nth-child(5) {
        animation-delay: 0.4s;
        transform: scale(0.4);
      }
      &:nth-child(6) {
        animation-delay: 0.5s;
        transform: scale(0.5);
      }
      &:nth-child(7) {
        animation-delay: 0.6s;
        transform: scale(0.6);
      }
      &:nth-child(8) {
        animation-delay: 0.7s;
        transform: scale(0.7);
      }
      &:nth-child(9) {
        animation-delay: 0.8s;
        transform: scale(0.8);
      }
      &:nth-child(10) {
        animation-delay: 0.9s;
        transform: scale(0.9);
      }
    }
  }
  @keyframes wave {
    0% {
      transform: scale(0.2);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.2);
    }
  }
}

// Footer
#Footer {
  i,
  svg:not(.xIcon) {
    padding-inline-start: 0 !important;
  }
  .xIcon {
    padding-inline-start: 0.5rem !important;
  }
  background-color: var(--Color6);
  .Links-Div {
    display: flex;
    flex-direction: column;
  }
  .link {
    color: #8c9499;
    text-decoration: none;
    transition: 0.5s;
    &:hover {
      color: var(--Color2);
      text-decoration: none;
    }
  }
  button {
    background-color: transparent;
    color: var(--wColor);
    border: 1px solid var(--wColor);
    &:hover {
      box-shadow: 0 0 2rem 0.5rem rgba(255, 255, 255, 0.2);
    }
    .MainBtn {
      background: transparent;
      text-decoration: none;
      color: inherit;
    }
  }
  .all-div {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  @media (max-width: 991px) {
    p.my-3 {
      margin-top: 0 !important;
    }
    .icons {
      justify-content: center;
    }
    .all-div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.alert {
  padding: 0.5rem 0 0 0;
  margin: 0;
  color: var(--Color4);
}
.rowX {
  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }
}
.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.three-doots {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.wrap {
  flex-wrap: wrap;
  & > p {
    margin-inline-end: 0.5rem;
  }
}
.show-more {
  text-decoration: none !important;
  color: var(--Color1) !important;
  opacity: 0.8;
  transition: 0.5s;
  &:hover {
    opacity: 1;
  }
}
#NewsSecHome,
#ProjectsSecHome,
#CertificatesSecHome {
  background: var(--gColor3);
}
.ms-auto {
  margin-left: unset !important;
  margin-right: unset !important;
  margin-inline-start: auto !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 10000 !important;
}

.fade.modal.show {
  z-index: 9999;
}
.cursor-pointer {
  cursor: pointer;
}

// HOME PAGE
// MainSecHome
#MainSecHome {
  position: relative;
  @media (max-width: 767px) {
    height: 100vh;
    .carousel {
      .carousel-inner {
        .carousel-item {
          img {
            height: 100vh;
          }
        }
      }
    }
  }
  padding: 0;
  .carousel {
    direction: ltr;
    .carousel-inner {
      .carousel-item {
        position: relative;
        img {
          width: 100%;
          @media (max-width: 767px) {
            width: auto;
          }
        }
        .text {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(180deg, rgb(0 0 0 / 75%), transparent);
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          user-select: none;
          h2 {
            font-size: 4.5rem;
            transition: 1s 0.3s;
            @media (max-width: 767px) {
              font-size: 2.5rem;
            }
          }
          p {
            transition: 1s;
          }
          h2,
          p {
            color: #fff;
            position: relative;
            left: 0;
            bottom: 0;
            transform: scale(1);
          }
        }
      }
      .carousel-item:not(.active) {
        .text {
          h2,
          p {
            left: 100%;
            bottom: 100%;
            transform: scale(0);
          }
        }
      }
    }
    .allBtns {
      button {
        &:hover::after {
          left: 0;
        }
        &:hover svg {
          color: var(--Color3);
        }
        &::after {
          content: "";
          position: absolute;
          left: -100%;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          background-color: var(--Color3);
          transition: 0.5s;
        }
        height: 3rem;
        width: 3rem;
        opacity: 1;
        overflow: hidden;
        @media (max-width: 576px) {
          height: 2.5rem;
          width: 2.5rem;
        }
        svg {
          color: var(--Color1);
          transition: 0.5s;
        }
      }
      .carousel-control-prev {
        top: calc(50% - 3rem);
      }
      .carousel-control-next {
        top: calc(50%);
      }
      @media (max-width: 576px) {
        .carousel-control-prev {
          top: calc(50% - 2.5rem);
        }
      }
    }
  }
  .scroll {
    height: 50px;
    width: 30px;
    border: 2px solid #fff;
    cursor: pointer;
    z-index: 1;
    .scrollX {
      width: 10px;
      height: 10px;
      animation: scroll 1.2s infinite linear;
    }
    @keyframes scroll {
      0% {
        top: 0;
        opacity: 0.2;
      }
      100% {
        top: calc(100% - 16px);
        opacity: 1;
      }
    }
  }
}
// AboutSecHome
#AboutSecHome {
  .row {
    .left-side {
      button {
        a {
          color: var(--Color1);
          text-decoration: none;
          transition: 0.5s;
        }
        &:hover {
          a {
            color: var(--wColor);
          }
        }
      }
      @media (min-width: 1400px) {
        .w-md-70 {
          width: 70%;
        }
      }
    }
    .right-side {
      align-items: center;
      @media (max-width: 991px) {
        img {
          width: 70%;
        }
      }
    }
    .card {
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 1/1;
      transform: scale(1);
      transition: 0.5s;
      &:hover {
        box-shadow: 0 0 20px -7px var(--Color1);
      }
      .main-counter {
        display: flex;
        flex-direction: row-reverse;
        font-weight: 600;
      }
    }
  }
}
// NewsSecHome
#NewsSecHome,
#NewsDetails {
  .newsDiv {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    .text {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      padding: 0.5rem;
      background: #3b5e6e80;
      color: var(--wColor);
      font-size: 1.5rem;
      font-weight: 600;
      backdrop-filter: blur(5px);
      transition: 0.5s;
    }
    &:hover .text {
      left: 0;
      bottom: 0;
    }
  }
}
// ServicesSecHome
#ServicesSecHome,
#Projects {
  .atropos.my-atropos {
    height: 100%;
    .card {
      height: 100%;
      .card-body {
        display: flex;
        flex-direction: column;
        .head-of-body {
          flex-grow: 1;
          background: linear-gradient(45deg, #1b6b9385, transparent);
        }
        img {
          aspect-ratio: 2/1;
        }
      }
    }
  }

  .card-body {
    padding: 0;
    .head-of-body {
      position: relative;
      overflow: hidden;
      &::after {
        content: "";
        position: absolute;
        width: 40%;
        height: 200%;
        left: -100%;
        top: -50%;
        background-color: rgb(255 255 255 / 10%);
        transition: 1s;
        transform: rotate(30deg);
      }
      &:hover::after {
        left: 150%;
      }
    }
    .body-of-body {
      padding: 1rem;
      .card-title {
        color: var(--Color1);
      }
      a {
        color: var(--bColor);
        p {
          margin: 0 !important;
        }
        text-decoration: none;
        display: inline-block;
        opacity: 0.8;
        transition: 0.5s;
        &:hover {
          opacity: 1;
        }
      }
      .card-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  // animations for services section
  .fade-enter {
    opacity: 0;
    transform: translateY(20px);
  }
  .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1s, transform 1s;
  }
  .fade-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s, transform 1s;
  }
}
// ProjectsSecHome
#ProjectsSecHome {
  overflow-x: hidden !important;
  .vertical-timeline::before {
    background: var(--gColor2);
  }
  .vertical-timeline-element-icon.bounce-in {
    background: var(--Color1);
    color: var(--wColor);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
// PartnersSecHome
#PartnersSecHome {
  .swiper-wrapper {
    align-items: center;
    .partnerDiv {
      .partnerImg {
        user-select: none;
        // aspect-ratio: 16/10;
        max-width: 100%;
        filter: grayscale(0.7);
        transition: 0.5s;
        &:hover {
          filter: grayscale(0);
        }
      }
    }
  }
}
// CertificatesSecHome
#CertificatesSecHome {
  .containerX {
    display: flex;
    justify-content: center;
    .certificatesImg {
      transition: 0.5s;
      &:hover {
        transform: scale(1.02);
      }
    }
  }
}

#ContactSecHome,
#CareerDetails {
  .container {
    .form {
      .row {
        .form-outline {
          margin: 10px 0;
          position: relative;
          .invalidTooltip {
            position: relative;
            top: 0;
            margin: 10px 0;
            font-size: 18px;
            background-color: transparent;
            color: var(--Color1);
            padding: 0;
          }
          input,
          textarea {
            box-shadow: none;
            outline: none;
            border: 2px solid var(--Color1);
            color: var(--Color1);
            background: transparent;
            &:focus {
              border: 2px solid var(--Color5);
            }
          }
          .textarea {
            height: 150px;
            vertical-align: bottom;
          }
          #submit {
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

// ABOUT PAGE
#About {
  .rowX {
    .left-side {
      ul {
        list-style: none;
        li {
          position: relative;
          &::before {
            content: "➤";
            position: absolute;
            left: -25px;
            top: 50%;
            transform: translateY(-50%);
            color: var(--Color1);
            font-size: 1.2rem;
          }
        }
      }
    }
    .right-side {
      .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded {
        background: var(--gColor3);
      }
    }
  }
}

// News Details PAGE
#NewsDetails,
#NewsSecHome {
  .all-news {
    p {
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
      color: var(--gColor);
    }
  }
  .swiper-wrapper {
    img {
      aspect-ratio: 2/1;
    }
  }
}

// Careers PAGE
#Careers {
  .text-justify {
    line-height: 1.8rem;
  }
  .card {
    padding: 1rem;
    .allCard:not(.allCardX) {
      align-items: center;
    }
    .allCard {
      display: flex;
      i,
      svg {
        background-color: var(--gColor3);
        border-radius: 10px;
        margin-inline-end: 0.5rem;
        font-size: 1.5rem;
        padding: 0.5rem;
        height: fit-content;
      }
    }
    button {
      &:hover {
        box-shadow: 0 0 20px -2px var(--Color1);
      }
    }
  }
}

// Career Details PAGE
#CareerDetails {
  .allCard {
    .allCardX {
      display: flex;
      align-items: center;
      h5 {
        margin: 0;
      }
      i,
      svg {
        background-color: var(--gColor3);
        border-radius: 10px;
        margin-inline-end: 0.5rem;
        font-size: 1.5rem;
        padding: 0.5rem;
        height: fit-content;
      }
    }
    p {
      margin-bottom: 0.5rem;
      text-align: justify;
    }
  }
}

// Gallery
#Gallery {
  .main {
    margin-top: 0.5rem;
  }
  .title {
    width: fit-content;
    position: relative;
    margin-bottom: 1.5rem;
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100px;
      height: 3px;
      background-color: var(--Color1);
    }
  }
  .imgs {
    columns: 3 250px;
    img {
      margin-bottom: 1rem;
    }
  }
}

// Projects PAGE
#Projects {
  input {
    box-shadow: none;
    outline: none;
    border: 2px solid var(--Color1);
    color: var(--Color1);
    background: transparent;
    &:focus {
      border: 2px solid var(--Color5);
    }
  }
}

// NotFound
#NotFound {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  a {
    color: var(--Color1);
    text-decoration: none;
  }
  .error {
    width: 50%;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
}

.ar {
  font-family: "Cairo", sans-serif;
  p.MuiTypography-root {
    font-family: "Cairo";
  }
  .vertical-timeline-element-content .vertical-timeline-element-date {
    float: right !important;
    text-align: end;
    font-family: "Roboto", sans-serif;
  }
  #NavBar {
    ul {
      padding: 0;
    }
    .langDiv {
      button {
        font-family: "Roboto", sans-serif;
      }
    }
  }
  #Footer {
    .row {
      div:not(.align-items-centerX, .icons) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      p {
        text-align: start;
      }
      ul {
        padding: 0;
      }
      @media (max-width: 991px) {
        .div {
          align-items: center !important;
          .Links-Div,
          .all-div {
            align-items: center !important;
          }
          p,
          a {
            text-align: center !important;
          }
        }
      }
    }
  }
  .toUp {
    right: unset;
    left: 25px;
  }
  // Home PAGE
  #ProjectsSecHome {
    .vertical-timeline-element-content.bounce-in {
      direction: rtl;
    }
  }
  // ABOUT PAGE
  #About {
    .rowX {
      .left-side {
        ul {
          li {
            &::before {
              transform: translateY(-50%) scaleX(-1);
              right: -25px;
              left: unset;
            }
          }
        }
      }
    }
  }
  #Gallery {
    .title {
      &::after {
        left: unset;
        right: 0;
      }
    }
  }
}
