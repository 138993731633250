#NavBar {
  transition: 1s;
  box-shadow: 0px 1px 20px -5px rgba(79, 192, 208, 0.2705882353);
  position: fixed !important;
  z-index: 9999;
  width: 100%;
  background: var(--wColor);
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
}
#NavBar nav {
  min-height: 3.5rem;
  box-shadow: 0 -10px 20px var(--Color1) !important;
}
#NavBar nav .navbar-brand,
#NavBar nav .nav-link {
  color: var(--bColor) !important;
  font-weight: 600;
}
#NavBar nav .main-btn {
  color: #fff !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
#NavBar nav .navbar-toggler {
  position: relative;
  border: none;
  box-shadow: none;
  outline: none;
}
#NavBar nav .navbar-toggler:focus {
  box-shadow: none;
}
#NavBar nav .navbar-toggler[aria-expanded=true] span:nth-child(2) {
  opacity: 0;
}
#NavBar nav .navbar-toggler[aria-expanded=true] span:first-child, #NavBar nav .navbar-toggler[aria-expanded=true] span:last-child {
  top: 6px;
  background-color: var(--Color1);
}
#NavBar nav .navbar-toggler[aria-expanded=true] span:first-child {
  transform: rotate(45deg);
}
#NavBar nav .navbar-toggler[aria-expanded=true] span:last-child {
  transform: rotate(-45deg);
}
#NavBar nav .navbar-toggler span {
  background-color: var(--bColor);
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0;
  transition: all 0.3s ease;
}
#NavBar nav .navbar-toggler span:first-child {
  top: 0;
}
#NavBar nav .navbar-toggler span:nth-child(2) {
  top: 6px;
}
#NavBar nav .navbar-toggler span:last-child {
  top: 12px;
}
#NavBar nav .navbar-nav .nav-item {
  display: flex;
  align-items: stretch;
  justify-content: center;
}
#NavBar nav .navbar-nav .nav-item .nav-link {
  opacity: 0.7;
  transition: 0.5s;
}
#NavBar nav .navbar-nav .nav-item .nav-link:hover {
  opacity: 1;
}
#NavBar nav .navbar-nav .nav-item .nav-link.active {
  opacity: 1;
}
#NavBar nav button:not(.navbar-toggler) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  background: transparent;
  padding: 0.3rem 1.5rem;
}
#NavBar nav button:not(.navbar-toggler):hover {
  background: var(--Color1);
  box-shadow: 0 0 2rem 0.5rem rgba(27, 107, 147, 0.1);
}
#NavBar nav button.navbar-toggler {
  margin: 0 0.5rem;
}

.NavBarFixed {
  background: rgba(255, 255, 255, 0.75) !important;
}/*# sourceMappingURL=NavBar.css.map */